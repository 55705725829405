.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.fa-edit{
  color:lightblue;
}

.fa-edit:hover{
  color:deepskyblue;
}

.fa-print{
  color:lime
}

.fa-print:hover{
  color:limegreen;
}

.fa-trash{
  color:red
}

.fa-trash:hover{
  color:darkred;
}

.fa-comment{
   color:dodgerblue;
}

.fa-comment:hover{
  color:blue
}

.fa-user{
  color:rosybrown;
}

.fa-user:hover{
  color:saddlebrown;
}


/* Slider */

.slide-container {
  width: 100%;
  margin: auto;
  margin-top: 120px;
}

h3 {
  text-align: center; }

.each-slide > div {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 300px;
  box-shadow: 0px 0px 7.2px 2.8px rgba(0, 0, 0, 0.35);
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

h3 {
  text-align: center; }

.each-slide > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 700px;
  width: 100%;
}

.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.each-fade {
  display: flex;
}

.each-fade .image-container {
  width: 100%;
  overflow: hidden;
}

.each-fade .image-container img {
  width: 100%;
}

.each-fade h2 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #adceed;
}

.card1{
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.center1{
  display: flex;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.card2 {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.card3 {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  padding: 30px;
  width: 950px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.link-f{
  display: flex;
  justify-content: center;
}

.tabs {
  width: 900px;
}

.link-for{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.point_ranking{
  width: 100%;
}

.form-w{
  width: 60% !important;
}

.login-form {
  width: auto;
}

.heading1 {
  text-align: center;
}

.input-box {
  width: 70px !important;
}

.all-cards {
  width: 400px;
  margin: 10px !important;
  display: block;
  transition: 0.3s;
}

.all-cards:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}


.cards-flex {
  padding: 50px 0;
}

.card-foot {
  padding: 5px 10px;
}

.list-head {
  display: flex;
  justify-content: space-around;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  flex: 1;
  position: sticky !important;
  top: 0;
  z-index: 1;
  background-color: #fff;
}

.img-div {
  width: 120px;
}

.mid-div {
  flex: 2;
}

.last-div {
  flex: 1;
}

.card-title {
  border: 1px solid black;
  padding: 5px 10px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.ads-show {
  border-bottom: solid #000000 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ads-show1 {
  border-top: solid #000000 1px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.image-ads { 
  width: 400px;
  height: 400px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

img {
  border-radius: 5px 5px 0 0;
}

.card-top {
  border-top: 1px solid rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.card-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.2);
}

.card-head {
  padding: 5px 10px;
  flex: 1;
  height: auto;
  text-align: center;
}

.show-mobile {
  display: none;
}

.end {
  border-left: 1px solid rgba(0,0,0,0.2);
}

.start {
  border-right: 1px solid rgba(0,0,0,0.2);
}

.mid1 {
  border-left: 1px solid rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
}

.card-data {
  border: 5px solid black;
  margin: 20px;
  border-radius: 10px;
  width: 50%;
}

.set-size {
  font-size: 26px;
}

.list-item {
  background-color: #fff;
  transition: 0.3s;
  width: 100%;
  height: 200px;
}

@media(max-width: 1100px){
  .card2{
    width: 70%;
  }

  .card3 {
    width: 850px;
  }

  .tabs {
    width: 800px;
  }

  .form-w{
    width: 70% !important;
  }

  .card-data {
    width: 60%;
  }

  .set-size {
    font-size: 24px;
  }
}

@media(max-width: 900px){
  .card2 {
    width: 80%;
  }

  .card3 {
    width: 650px;
  }

  .tabs {
    width: 600px;
  }

  .form-w {
    width: 80% !important;
  }

  .card-data {
    width: 70%;
  }
  
  .set-size {
    font-size: 22px;
  }
}

@media(max-width: 700px){
  .show-window {
    display: none;
  }

  .card3 {
    width: 550px;
  }

  .tabs {
    width: 530px;
  }

  .show-mobile {
    display: block;
  }
}

@media(max-width: 600px){
  .card1{
    width: 95%;
    margin-left: 2%;
  }

  .card2{
    width: 90%;
  }

  .card3 {
    width: 450px;
  }

  .tabs {
    width: 430px;
  }

  .form-w{
    width: 90% !important;
  }

  .slide-container{
    margin-top: 100px;
  }
  .cards-flex {
    margin-left: -10px;
  }

  .card-data {
    width: 95%;
  }

  .all-cards {
    width: 95%;
  }

  .set-size {
    font-size: 18px;
  }
}


@media(max-width: 400px) {
  .set-size {
    font-size: 14px;
  }

  .card3 {
    width: 350px;
  }

  .tabs {
    width: 330px;
  }

  .mobile-ver {
    flex-direction: column;
  }

  .input-box {
    width: 40px !important;
  }

}


.mobile-ver {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.ant-modal-body {
  height: 500px !important;
  overflow-y: scroll;
}

.ant-descriptions-title {
  margin-bottom: 5px !important;
}